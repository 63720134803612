@import '../../../../../styles/config';
@import '../../../../../styles/mixins/typography';
@import '../../../../../styles/mixins/inputs';

.main-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-container {
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
  padding: 1rem;
  background: white;
}

.filters-wrapper {
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
  padding: 1rem;
  background: white;
}

.filters-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1rem;

  @include breakpoint(small, max) {
    margin-right: 0;
    width: 100%;
  }
}

.filter-buttons {
  color: map-get($map: $colors, $key: copyDark) !important;
  white-space: nowrap;

  &-selected {
    border: 1px solid map-get($map: $colors, $key: primary) !important;
    color: map-get($map: $colors, $key: copyDark) !important;
  }
}

.sort-section-desktop {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 0.5rem;

  @include breakpoint(small, max) {
    display: none;
  }
}

.sort-section-mobile {
  display: none;

  @include breakpoint(small, max) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 160px;
  }
}

.filter-container {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  @include breakpoint(small, max) {
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.filter-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  &:first-child {
    flex: 1;
  }

  @include breakpoint(small, max) {
    width: 100%;
    margin-bottom: 8px;
    justify-content: space-between;

    &:first-child {
      flex: none;
    }
  }
}

.dropdown-container {
  display: flex;
  justify-content: center;

  nav {
    padding: 2rem;
    padding-top: 0;

    a {
      @include copy(small);
      cursor: pointer;
      display: inline-block;
      width: 100%;
      margin-left: 0;
      padding-top: 1rem;
      padding-bottom: 0;
    }
  }
}

.dropdown-menu-content {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
}

.parent-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  @include breakpoint(small, max) {
    width: 160px;
  }

  .filter-buttons {
    min-width: max-content;
  }
}

.sort-label {
  font-size: 14px;
  color: var(--copy-dark);
  font-weight: 500;
}

.dropdown-menu,
.dropdown-menu-mobile {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 50;
  margin-top: 4px;
  background: white;
  border: 1px solid var(--gray-200);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 200px;

  > div {
    transform: none !important;
    padding: 0 !important;
  }

  @include breakpoint(small, max) {
    min-width: 160px;
    right: 0;
    left: auto;
  }
}

.arrow {
  margin-top: 0.2rem;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 0;

  &-row {
    padding: 0.75rem 1rem;
    cursor: pointer;
    @include type($font: copy, $size: small, $color: copy);
    line-height: 1.6;
    transition: background-color 0.2s ease;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .count {
      color: var(--copy-light);
      margin-left: 0.5rem;

      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }

    &:hover {
      background-color: var(--gray-100);
    }

    &.selected {
      color: var(--primary);
      font-weight: 500;
    }

    &.show-more {
      color: var(--primary);
      justify-content: center;
      font-weight: 500;
      border-top: 1px solid var(--gray-200);
      margin-top: 0.5rem;
    }
  }
}

.clear-all-button {
  color: map-get($map: $colors, $key: primary);
  margin-left: 0.5rem;
  padding-top: 1rem;
  white-space: nowrap;
  max-width: fit-content;
}

.applied-filters-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  overflow-x: auto;
  flex-wrap: nowrap;
  background-color: transparent;
  padding: 0.5rem 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  @include breakpoint(small, max) {
    width: calc(100vw - 120px);
  }
}

.applied-filters-container::-webkit-scrollbar {
  display: none !important;
}

.pill {
  display: flex;
  justify-content: space-between;
  background-color: $primary-100;
  align-items: center;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  width: fit-content;
  height: 2rem;
}

.sort-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 220px;

  @include breakpoint(small, max) {
    width: 160px;
  }
}

.sort-container select {
  padding: 0.78rem 0.7rem;

  @include breakpoint(small, max) {
    font-size: small;
  }
}

.buttons-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: nowrap;

  @include breakpoint(small, max) {
    width: calc(100% - 160px);
    flex-wrap: wrap;
  }
}

.pill-icon {
  display: flex;

  svg {
    width: 1rem !important;
    min-width: 0.5rem !important;
    margin-left: 0.5rem;
    margin-right: 0 !important;
    justify-content: space-between;
    align-items: center;
  }
}

.books-found {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--gray-200);
  color: var(--copy);
  font-size: 0.875rem;
  font-weight: 500;
}
